/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $(function() {

    $('.remove_employee').on('click',function()
    {
      var employee_id = $(this).data('employee_id');

      if (confirm('Weet je zeker dat je deze werknemer wilt verwijderen?')) {
        $('#' + employee_id).remove();
      }

      return false;
    });

    var employee_count = $(".um-employee").length + 1;

    $('#duplicate_employee').click(function(){

       employee_count++;

      //increase incremental
      // var increment_id = $('.um-employee.duplicator').data('incrementid');
      // increment_id++;
      // alert(increment_id);
      
      $('.um-employee.duplicator').clone().appendTo('.um_employees').removeClass('hidden').removeClass('duplicator').find("input").each(function() {
        this.name = this.name.replace(/\d+/, employee_count);
      });

      return false;
    });

    //check if login form has errors.
    if($('#loginmodal .um-form p.um-notice').hasClass('err')){
      $('#loginmodal').modal('show');
    }

    $('.company-list .item .tag label, .filter-content label').on('click', function() {
      $.smoothScroll({
        scrollTarget: '#response',
        offset:-120
      });
    });

    $("body").on("appear", "#loadmore-btn", function() {
        // Do something...
        alert('visible');
    });

    $('.mouse-scroll-down').smoothScroll({
      offset:-120
    });

    $('.company-list .text-holder').matchHeight();

    //close filters for mobile users.
    
    if($(window).width() < 768){
      $('.filter').addClass('closed');
    }

    $('.filter .heading-holder').click(function(){

      var parent = $(this).parent();

      if(parent.hasClass('closed')){
        parent.removeClass('closed');
      }else{
        parent.addClass('closed');
      }
    });

    var p = new Parallax('.parallax', {
        offsetYBounds: 0,
        intensity: 30,
        center: 0.9,
        safeHeight: 0.25
      }).init();

    $('.gallery-block').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      callbacks: {
        elementParse: function(item) {
          console.log(item.el[0].className);
          if(item.el[0].classList.contains('video')) {
            item.type = 'iframe',
            item.iframe = {
               patterns: {
                 youtube: {
                   index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
                   id: 'v=', // String that splits URL in a two parts, second part should be %id%
                    // Or null - full URL will be returned
                    // Or a function that should return %id%, for example:
                    // id: function(url) { return 'parsed id'; } 
                   src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe. 
                 },
                 vimeo: {
                   index: 'vimeo.com/',
                   id: '/',
                   src: '//player.vimeo.com/video/%id%?autoplay=1'
                 },
                 gmaps: {
                   index: '//maps.google.',
                   src: '%id%&output=embed'
                 }
               }
            }// jshint ignore:line
          } else {// jshint ignore:line
             item.type = 'image',
             item.tLoading = 'Loading image #%curr%...',
             item.mainClass = 'mfp-img-mobile',
             item.image = {
               tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
             }// jshint ignore:line
          }// jshint ignore:line

        }
      }
    });


    // $('.gallery-block').magnificPopup({
    //   delegate: 'a', // child items selector, by clicking on it popup will open
    //   type: 'image',
    //   gallery:{
    //     enabled:true
    //   }
    //   // other options
    // });

    $('.switch a').on('click',function(){

      $('.board').data('action', $(this).data('action'));

      $('.current.agendaload').click();

      $('.switch a').removeClass('selected');
      $(this).addClass('selected');

      return false;

    });

    $(".filterform, .stick-in-parent").stick_in_parent({
      offset_top : 130
    });

    $('.agenda-items .item').on('click',function(){

      var title = $(this).find('.the-title').text();
      var content = $(this).find('.content-to-clone').html();
      var datet = $(this).find('.datestamp').text();
      var timestamp = $(this).find('.timestamp').text();

      $('#modal .timestamp-holder').text(timestamp);
      $('#modal .title-holder').text(title);
      $('#modal .content-holder').html(content);
      $('#modal .date-holder').text(datet);
      
    });

    $('.agendaload').click(function(){

      var week = '';
      var year = '';
      var action = 'agendafilter';
      var url = $('.board').data('url');

      if($('.board').data('action')){
        action = $('.board').data('action');
      }

      //get direction.
      if($(this).data('direction') === 'prev'){
        week = $('.board').data('prev-week');
        month = $('.board').data('prev-month');
        year = $('.board').data('prev-year');
      }else if($(this).data('direction') === 'next'){
        week = $('.board').data('next-week');
        month = $('.board').data('next-month');
        year = $('.board').data('next-year');
      }else if($(this).data('direction') === 'current'){
        week = $('.board').data('current-week');
        month = $('.board').data('current-month');
        year = $('.board').data('current-year');
      }

      console.log('week:'+week);
      console.log('month:'+month);
      console.log('year:'+year);
      console.log('action:'+action);

      $.ajax({
        url:url,
        data: {action:action, week: week, year: year, month:month},
        type:'POST', // POST
        beforeSend:function(xhr){
          $('.loading-block').show();
          //$('.loading-block').removeClass('hideme').text('We zoeken de beste deals...'); // changing the button label
        },
        success:function(data){
          $('.loading-block').hide();
          //$('.filter-status').text('Deals gevonden!').addClass('hideme'); // changing the button label back
          $('#response').html(data); // insert data
        }
      });
      return false;
    });

    $(document).on("click", ".btn-loadmore", function(){
      console.log("btn-loadmore clicked");
      var action          = $(this).data('action');
      var branche         = $(this).data('branche');
      var gemeente        = $(this).data('gemeente');
      var thema           = $(this).data('thema');
      var offset          = $(this).data('offset');  //make this number the same as into the ajax filter and php first load file
      var url             = $(this).data('url');
      var orderby         = $(this).data('orderby');
      var orderdirection  = $(this).data('orderdirection');

      $.ajax({
        url:url,
        data:{action:action,branche:branche,gemeente:gemeente,thema:thema,offset:offset,orderdirection:orderdirection,orderby:orderby}, // form data
        type:'POST', // POST
        beforeSend:function(xhr){
          $('.btn-loadmore span.load-animation').show(500);
          $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
        },
        success:function(data){
          $('.btn-loadmore span.load-animation').hide(500);
          $('.btn-loadmore').closest('.loadmore-wrapper').remove();
          $('#response').append(data); // insert data
          $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
        }
      });
      return false;
    });

    // $('.btn-loadmore').on('click',function(){
    //   console.log("btn-loadmore clicked");
    //   var action          = $(this).data('action');
    //   var branche         = $(this).data('branche');
    //   var gemeente        = $(this).data('gemeente');
    //   var thema           = $(this).data('thema');
    //   var offset          = $(this).data('offset');  //make this number the same as into the ajax filter and php first load file
    //   var url             = $(this).data('url');
    //   var orderby         = $(this).data('orderby');
    //   var orderdirection  = $(this).data('orderdirection');

    //   $.ajax({
    //     url:url,
    //     data:{action:action,branche:branche,gemeente:gemeente,thema:thema,offset:offset,orderdirection:orderdirection,orderby:orderby}, // form data
    //     type:'POST', // POST
    //     beforeSend:function(xhr){
    //       $('.btn-loadmore span.load-animation').show(500);
    //       $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
    //     },
    //     success:function(data){
    //       $('.btn-loadmore span.load-animation').hide(500);
    //       $('.btn-loadmore').closest('.loadmore-wrapper').remove();
    //       $('#response').append(data); // insert data
    //       $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
    //     }
    //   });
    // });

    // $('.btn-loadmore').on('click',function(){
    //   console.log('btn-loadmore');
    //   var action          = $(this).data('action');
    //   var branche         = $(this).data('branche');
    //   var gemeente        = $(this).data('gemeente');
    //   var thema           = $(this).data('thema');
    //   var offset          = $(this).data('offset');  //make this number the same as into the ajax filter and php first load file
    //   var url             = $(this).data('url');
    //   var orderby         = $(this).data('orderby');
    //   var orderdirection  = $(this).data('orderdirection');

    //   $.ajax({
    //     url:url,
    //     data:{action:action,branche:branche,gemeente:gemeente,thema:thema,offset:offset,orderdirection:orderdirection,orderby:orderby}, // form data
    //     type:'POST', // POST
    //     beforeSend:function(xhr){
    //       $('.btn-loadmore span.load-animation').show(500);
    //       $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
    //     },
    //     success:function(data){
    //       $('.btn-loadmore span.load-animation').hide(500);
    //       $('.btn-loadmore').closest('.loadmore-wrapper').remove();
    //       $('#response').append(data); // insert data
    //       $(".filterform, .stick-in-parent").trigger("sticky_kit:recalc");
    //     }
    //   });
    //   return false;
    // });

    


    $('.filter-option').change(function(){
      $('.filterform').submit();
    });

    $('.filterform').submit(function(){
      var filter = $('.filterform');
      $.ajax({
        url:filter.attr('action'),
        data:filter.serialize(), // form data
        type:filter.attr('method'), // POST
        beforeSend:function(xhr){
          $('.loading-block').show();
          //$('.loading-block').removeClass('hideme').text('We zoeken de beste deals...'); // changing the button label
        },
        success:function(data){
          $('.loading-block').hide();
          //$('.filter-status').text('Deals gevonden!').addClass('hideme'); // changing the button label back
          $('#response').html(data); // insert data
        }
      });
      return false;
    });

    //set filters if hashtag has been set.
    var hashValue = window.location.hash.substr(1);
    if(hashValue){
      $('#'+hashValue).prop("checked",true);
      $('.filterform').submit();
       $.smoothScroll({
        scrollTarget: '#response',
        offset:-120
      });
    }


    function center_map( map ) {

      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

      });

      // only 1 marker?
      if( map.markers.length === 1 )
      {
        // set center of map
          map.setCenter( bounds.getCenter() );
          map.setZoom( 16 );
      }
      else
      {
        // fit to bounds
        map.fitBounds( bounds );
      }

    }

    function add_marker( $marker, map ) {

      // var
      var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

      // create marker
      var marker = new google.maps.Marker({
        position  : latlng,
        map     : map
      });

      // add to array
      map.markers.push( marker );

      // if marker contains HTML, add it to an infoWindow
      if( $marker.html() )
      {
        // create info window
        var infowindow = new google.maps.InfoWindow({
          content   : $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {

          infowindow.open( map, marker );

        });
      }

    }


    function new_map( $el ) {
      
      // var
      var $markers = $el.find('.marker');
      
      
      // vars
      var args = {
        zoom    : 16,
        disableDefaultUI: true,
        center    : new google.maps.LatLng(0, 0),
        mapTypeId : google.maps.MapTypeId.ROADMAP
      };
      
      // create map           
      var map = new google.maps.Map( $el[0], args);
      
      // add a markers reference
      map.markers = [];
      
      // add markers
      $markers.each(function(){
        
          add_marker( $(this), map );
        
      });
      
      // center map
      center_map( map );
      
      // return
      return map;
      
    }

    /*
    *  document ready
    *
    *  This function will render each map when the document is ready (page has loaded)
    *
    *  @type  function
    *  @date  8/11/2013
    *  @since 5.0.0
    *
    *  @param n/a
    *  @return  n/a
    */
    // global var
    var map = null;


    $('.acf-map').each(function(){

      map = new_map( $(this) );

    });

    $('.toggle-search').click(function(){

      if($('#search-header').hasClass('open')){
        $('#search-header').removeClass('open');
      }else{
        $('#search-header').addClass('open');
        $('#search').focus();
      }

      return false;

    });

  	// $(".sticky-block").stick_in_parent({
   //    offset_top:132
   //  });

    // $('.service-block a').smoothScroll({offset: -140});

    $('.single-item').slick({
      // prevArrow:'.slide-left',
      // nextArrow:'.slide-right',
      appendDots:'.single-item-dots',
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    $('.multiple-items').slick({
      appendDots:'.multiple-items-dots',
      dots: true,
      infinite: true,
      slidesToShow: 2,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            slidesToScroll: 1
          }
        }
      ]
   });

    function set_body_class(height)
    {
      if(height  > 1) {
        $('body').addClass('scrolling');
      }else{
        $('body').removeClass('scrolling');
      }
    }
   
    $(window).scroll(function() {
      var height = $(window).scrollTop();
      set_body_class(height);
    });

    var height = $(window).scrollTop();
    set_body_class(height);

    var $menu = $("#primary-menu").mmenu({
      // options
      "extensions": [
        "position-right"
      ]
    }, {
      // configuration
      clone: true,
      scrollBugFix:{
        fix:true
      },
      offCanvas: {
        pageSelector: "#page-wrapper"
      }
    });

    var $icon = $("#open-menu");
    var API = $menu.data( "mmenu" );

    $icon.on( "click", function() {
      if($(this).hasClass('is-active')){
        API.close();
      }else{
        API.open();
      }
    });

    API.bind( "open:finish", function() {
       setTimeout(function() {
          $icon.addClass( "is-active" );
       }, 100);
    });
    API.bind( "close:finish", function() {
       setTimeout(function() {
          $icon.removeClass( "is-active" );
       }, 100);
    });

    

    API.bind( "open:finish", function() {
       setTimeout(function() {
          $icon.addClass( "is-active" );
       }, 100);
    });
    API.bind( "close:finish", function() {
       setTimeout(function() {
          $icon.removeClass( "is-active" );
       }, 100);
    });










  });



  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
